/* HeroSection.css */

/* Container for the hero section */
.hero-container {
    position: relative;
    width: 100vw;
    height: 100vh; /* Adjust height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    overflow: hidden;
    margin-top: 0; /* Ensure there's no margin at the top */
    padding-top: 0; /* Ensure there's no padding at the top */
  }
  
  /* Video background */
  .video-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video covers the whole container */
    z-index: -1; /* Sends the video behind the content */
  }
  
  /* Hero content */
  .hero-content {
    z-index: 1;
    max-width: 800px;
  }
  
  .hero-content h1 {
    font-size: 3.5rem;
    margin-bottom: 20px;
  }
  
  .hero-content p {
    font-size: 1.5rem;
    margin-bottom: 40px;
  }
  
  /* Button styles */
  .hero-buttons {
    display: flex;
    gap: 15px;
    justify-content: center;
  }
  
  .hero-buttons .btn {
    padding: 10px 20px;
    font-size: 1.2rem;
    color: white;
    background-color: #007BFF; /* Blue background */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .hero-buttons .btn-signup {
    background-color: #28a745; /* Green for signup */
  }
  
  .hero-buttons .btn-signup:hover {
    background-color: #218838;
  }
  
  .hero-buttons .btn-signin {
    background-color: #007bff; /* Blue for signin */
  }
  
  .hero-buttons .btn-signin:hover {
    background-color: #0056b3;
  }
  