.products-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.product-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.product-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.product-title {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
}

.product-description {
  display: none;
}


.product-price {
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.variant-label, .quantity-label {
  display: block;
  margin: 10px 0;
}

.product-colors {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.color-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.add-to-cart-button {
  padding: 10px 20px;
  background-color: #3a8f3a;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
