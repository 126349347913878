/* Set the background image to cover the whole page */
.about-us-container {
    height: 100vh;
    background-image: url('/Users/jenicemcdaniel/Desktop/alumni/src/images/4214.png'); /* Add your image path */
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    color: white;
  }
  
  .about-us-content {
    background-color: rgba(0, 0, 0, 0.6); /* Black transparent background */
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    z-index: 1;
    position: relative;
  }
  
  .about-us-content h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .about-us-content p {
    font-size: 1.5rem;
  }
  
 /* Container for flying photos */
.photo-animations {
    display: grid;  /* Use grid layout */
    grid-template-columns: repeat(3, 1fr); /* Three photos per row */
    gap: 20px; /* Add some space between the photos */
    width: 100%;
    margin-top: 40px; /* Give some margin to avoid overlap with text */
  }
  
  /* Common styling for photos */
  .photo-fly-in {
    width: 180px; /* Increased size of the photos */
    height: 180px;
    object-fit: cover; /* Maintain aspect ratio without distortion */
    border-radius: 10px;
    z-index: 0;
    opacity: 0;
    animation-duration: 8s; /* Total duration including flying in, staying, and flying out */
    animation-fill-mode: forwards;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    position: relative;
  }
  
  /* Flying in from the left at an angle */
  .photo-left {
    animation-name: flyInOutLeft;
  }
  
  /* Flying in from the right at an angle */
  .photo-right {
    animation-name: flyInOutRight;
  }
  
  /* Keyframe animation for flying in and flying out from the left */
  @keyframes flyInOutLeft {
    0% {
      opacity: 0;
      transform: translateX(-200px) rotate(-30deg);
    }
    20% {
      opacity: 1;
      transform: translateX(0) rotate(0deg);
    }
    80% {
      opacity: 1;
      transform: translateX(0) rotate(0deg);
    }
    100% {
      opacity: 0;
      transform: translateX(200px) rotate(-30deg);
    }
  }
  
  /* Keyframe animation for flying in and flying out from the right */
  @keyframes flyInOutRight {
    0% {
      opacity: 0;
      transform: translateX(200px) rotate(30deg);
    }
    20% {
      opacity: 1;
      transform: translateX(0) rotate(0deg);
    }
    80% {
      opacity: 1;
      transform: translateX(0) rotate(0deg);
    }
    100% {
      opacity: 0;
      transform: translateX(-200px) rotate(30deg);
    }
  }