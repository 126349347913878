/* Shared styles for both Sign Up and Sign In */

.signup-container, .signin-container {
    background-image: url('/Users/jenicemcdaniel/Desktop/alumni/src/images/independence-high-school-columbus-oh.jpg'); /* Adjust the path */
    background-size: cover;
    background-position: center;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .signup-form, .signin-form {
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    padding: 40px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .signup-form input, .signin-form input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .signup-form button, .signin-form button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1rem;
  }
  