/* Styling for the UserProfile page */

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
}

/* Profile Info Section */
.profile-info {
  margin: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 50%;
  text-align: center;
}

.profile-info input {
  margin: 10px;
  padding: 10px;
  width: 80%;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Profile Photo Styling */
.profile-photo-container {
  position: relative;
  display: inline-block;
}

.profile-photo-circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ccc;
}

/* Container for Update and Save buttons */
.photo-buttons-container {
  display: flex;
  justify-content: center;
  gap: 10px; /* Add space between buttons */
  margin-top: 10px;
}

.update-photo-button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.update-photo-button:hover {
  background-color: #45a049;
}

.save-photo-button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-photo-button:hover {
  background-color: #45a049;
}

/* Cropper Container */
.cropper-container {
  width: 300px;
  height: 300px;
  position: relative;
  margin: 20px 0;
}

/* Password Change Section */
.password-change {
  margin: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 50%;
  text-align: center;
}

.password-change input {
  margin: 10px;
  padding: 10px;
  width: 80%;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
/* Notification styling */
.notification {
  color: green;
  font-weight: bold;
  margin-top: 10px;
}
