.alumni-spotlight {
  padding: 20px;
}

.alumni-spotlight h2 {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 40px;
  color: #333;
}

.spotlight-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.alumni-item {
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.alumni-left {
  flex: 6; /* Image takes up 6 columns */
}

.alumni-left img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.alumni-right {
  flex: 6; /* Text content takes up 6 columns */
  padding-left: 20px;
}

.alumni-right h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.alumni-right p {
  font-size: 1em;
  color: #555;
  margin-bottom: 10px;
}

.alumni-right .read-more {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.alumni-item:hover img {
  transform: scale(1.05); /* Image hover effect */
}
