.sponsors-section {
    background-color:  rgb(3, 3, 71); /* Light blue background */
    padding: 20px;
    text-align: center;
  }
  
  .sponsors-section h2 {
    color: white;
    margin-bottom: 20px;
  }
  .sponsor-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .sponsor-tile {
    background: white;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .sponsor-tile:hover {
    transform: scale(1.05);
  }
  
  .sponsor-logo {
    max-width: 100px;
    margin-bottom: 10px;
  }
  