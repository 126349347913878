/* General styles */
.order-tracking {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    margin: 20px;
    cursor: move; /* Indicates draggable component */
  }
  
  .order-tracking h2 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #333;
  }
  
  /* Table styles */
  .order-tracking table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .order-tracking th, .order-tracking td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
  }
  
  .order-tracking th {
    background-color: #4CAF50;
    color: white;
  }
  
  .order-tracking tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .order-tracking table {
      display: block;
      width: 100%;
      overflow-x: auto;
    }
  
    .order-tracking th, .order-tracking td {
      display: block;
      text-align: right;
      padding: 10px;
    }
  
    .order-tracking tr {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }
  
    .order-tracking th {
      background-color: #4CAF50;
      color: white;
      text-align: right;
      padding-top: 8px;
    }
  }
  