.shopping-cart-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .cart-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .cart-item {
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px;
    width: 300px;
    display: flex;
    align-items: center;
  }
  
  .cart-item-image {
    width: 100px;
    height: auto;
    margin-right: 10px;
  }
  
  .cart-item-details {
    flex-grow: 1;
  }
  
  .remove-button {
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .cart-summary {
    margin-top: 20px;
    text-align: center;
  }
  
  .checkout-button {
    background-color: blue;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
  }
  