/* Ensure the sidebar doesn't cover tiles */
.dashboard-container {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 250px; /* Set a fixed width for the sidebar */
  background-color: #2b2d42;
  padding: 20px;
}

.main-content {
  flex-grow: 1;
  margin-left: 250px; /* Push the content to the right to avoid sidebar overlap */
  padding: 20px;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2b2d42;
  color: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
}

.top-bar-icons {
  display: flex;
  gap: 15px;
}

.top-bar-icons a {
  color: white;
  font-size: 1.5rem;
}

.top-bar-profile {
  display: flex;
  align-items: center;
  gap: 15px;
}

.top-bar-profile .profile-photo {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.top-bar-profile a {
  color: white;
  font-size: 1.5rem;
}

/* Admin Dashboard Grid Layout */
.admin-dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Adjust min width for tiles */
  grid-gap: 20px;
  flex-grow: 1;
}

/* Tile/Box Styling */
.tile {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.tile:hover {
  transform: scale(1.05);
}

.tile h3 {
  margin-bottom: 10px;
  color: #333;
  font-size: 1.5em;
}

.tile p {
  margin: 5px 0;
  color: #555;
}

.tile ul {
  list-style-type: none;
  padding: 0;
}

.tile ul li {
  margin-bottom: 5px;
  color: #555;
}

.tile a {
  text-decoration: none;
  color: #2b2d42;
}

.tile a:hover {
  color: #d90429;
}
