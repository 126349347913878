/* Navbar styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: white; /* Set background color to white */
  color: black; /* Adjust text color for better contrast */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for separation */
  height: 60px; /* Adjust height to make the navbar shorter */
}

.navbar-logo h2 {
  cursor: pointer;
  font-family: 'Arial', sans-serif;
  color: black; /* Adjust logo color to match the new white background */
}

.navbar-links {
  list-style-type: none;
  display: flex;
  gap: 20px;
}

.navbar-links li {
  display: inline;
}

.navbar-links a {
  text-decoration: none;
  color: black; /* Adjust link color for better visibility on white background */
  font-family: 'Arial', sans-serif;
}

.navbar-links a:hover {
  color: gray; /* Optional: Change color on hover */
}

.navbar-profile {
  cursor: pointer;
}

.profile-photo {
  width: 40px; /* Increased width for better visibility */
  height: 40px; /* Increased height for better visibility */
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid black; /* Adjust border to black for contrast on white */
}
.navbar-cart {
  position: relative;
  margin-right: 20px;
}

.cart-icon {
  font-size: 24px;
  color: white;
  text-decoration: none;
}

.cart-count {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 14px;
}
