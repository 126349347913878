/* General styles */
.events-trips {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.add-section {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.add-section input,
.add-section textarea {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-section button {
  background-color: #4caf50;
  color: white;
  padding: 6px; /* Reduced padding for smaller buttons */
  font-size: 0.85em; /* Reduced font size for smaller text */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 80%; /* Reduced width */
  margin: 0 auto; /* Center the button */
  display: block;
}

.add-section button:hover {
  background-color: #45a049;
}

/* Styles for the tiles */
.tiles-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.tile {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  position: relative;
}

.tile img {
  width: 100%;
  height: auto;
  max-height: 150px;
  object-fit: cover;
  margin-bottom: 10px;
}

.tile h4 {
  margin-bottom: 10px;
  font-size: 1.25em;
}

.tile p {
  font-size: 0.9em;
  margin-bottom: 10px;
}

.tile button {
  background-color: #f44336;
  color: white;
  padding: 6px; /* Reduced padding */
  font-size: 0.85em; /* Reduced font size */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 80%; /* Reduced width */
  margin-bottom: 5px; /* Added margin between buttons */
}

.tile button:hover {
  background-color: #e53935;
}
