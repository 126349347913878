/* Events.css */

.events-section {
    padding: 20px;
    width: 100vw; /* Full viewport width */
    margin: 0; /* No margin */
    background-color: rgb(3, 3, 71);
    height: 50vh; /* Increase the height to fill more of the screen */
    display: flex;
    align-items: flex-start; /* Align content to the top */
  }
  
  .event-card {
    transition: transform 0.3s ease;
    padding-top: 20px; /* Add padding to the top of the cards */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .event-card:hover {
    transform: scale(1.05);
  }
  
  /* Sports Schedule Card Animation */
.animated-schedule-container {
    height: 200px;
    overflow: hidden;
    position: relative;
  }
  
  .scrolling-list {
    display: inline-block;
    position: absolute;
    animation: scrollUp 20s linear infinite; /* Slowed down the scroll to 20 seconds */
  }
  
  @keyframes scrollUp {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  
  /* Increase font size for the sports schedule */
  .scrolling-list .MuiListItemText-primary {
    font-size: 1.5rem; /* Larger font for the dates */
  }
  
  .scrolling-list .MuiListItemText-secondary {
    font-size: 1.2rem; /* Larger font for the opponent */
  }
  