.user-management-container {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .user-management-card {
    width: 100%;
    max-width: 1000px;
    height: auto;
    min-height: 400px;
    resize: both;
    overflow: auto;
    padding: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .scrollable-table {
    max-height: 300px; /* Adjust height for scrolling */
    overflow-y: auto;
  }
  
  /* Optional: Button styling */
  button {
    background-color: #3f51b5;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #303f9f;
  }
  