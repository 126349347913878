/* Contact Form Container */
.contact-form {
    max-width: 600px;
    margin: 50px auto;
    padding: 30px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form h2 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Form Group */
  .contact-form div {
    margin-bottom: 15px;
  }
  
  /* Labels */
  .contact-form label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
    color: #333;
    font-size: 16px;
  }
  
  /* Input, Select, and Textarea Fields */
  .contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form select,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    transition: all 0.3s ease-in-out;
  }
  
  .contact-form input[type="text"]:focus,
  .contact-form input[type="email"]:focus,
  .contact-form select:focus,
  .contact-form textarea:focus {
    border-color: #007bff;
    background-color: #fff;
  }
  
  /* Textarea Styling */
  .contact-form textarea {
    height: 150px;
    resize: vertical;
  }
  
  /* Button Styling */
  .contact-form button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  
  .contact-form button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Error and Success Messages */
  .error {
    color: #e74c3c;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .success {
    color: #2ecc71;
    margin-bottom: 10px;
    text-align: center;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .contact-form {
      padding: 20px;
    }
  
    .contact-form h2 {
      font-size: 22px;
    }
  
    .contact-form button {
      font-size: 16px;
    }
  }
  