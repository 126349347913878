/* General container styling */
.photo-approval-container {
    padding: 20px;
    margin: 0 auto;
    max-width: 1200px; /* Adjust the max width as needed */
  }
  
  /* Removed card styling and replaced with grid layout directly */
  /* Grid container for all photos */
  .photo-grid {
    display: flex;
    flex-wrap: wrap; /* Makes the items wrap inside the container */
    gap: 20px; /* Spacing between photo items */
    max-height: 600px; /* Max height before scroll */
    overflow-y: auto; /* Vertical scroll for overflow */
    border: 1px solid #ddd; /* Border for the container */
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    resize: both; /* Allow resizing */
    min-width: 300px; /* Prevent container from shrinking too much */
    min-height: 400px;
  }
  
  /* Styling for individual photo items */
  .photo-item {
    flex: 1 1 calc(25% - 20px); /* Four items per row, adjusts to container size */
    max-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #eee;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Box shadow for individual items */
  }
  
  /* Thumbnail styling */
  .thumbnail {
    width: 100%;
    height: 150px;
    object-fit: cover; /* Ensures thumbnail is properly scaled */
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  /* Button container for approve/deny actions */
  .photo-actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
  }
  
  /* Approved label styling */
  .approved-label {
    color: green;
    font-weight: bold;
    margin-top: 10px;
  }
  
  /* Responsive adjustments for mobile */
  @media (max-width: 768px) {
    .photo-item {
      flex: 1 1 calc(50% - 10px); /* Two items per row on smaller screens */
    }
  
    .thumbnail {
      height: 120px; /* Smaller thumbnails for smaller screens */
    }
  }
  