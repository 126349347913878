/* Checkout.css */

/* Basic styling for the product display section */
section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

/* Styling for the product image and description */
.product {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.product:hover {
  transform: scale(1.05);
}

.product img {
  max-width: 100%;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
}

.description {
  margin-top: 10px;
}

.description h3 {
  font-size: 1.5em;
  margin: 0;
  color: #333;
}

.description h5 {
  font-size: 1.2em;
  margin: 10px 0;
  color: #888;
}

/* Styling for the checkout button */
button[type="submit"] {
  background-color: #6772e5;
  color: white;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #5469d4;
}

/* Styling for the message section */
section p {
  font-size: 1.2em;
  color: #333;
  margin-top: 20px;
}
