/* Home.css */
.home-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.hero-section, .events-section, .trips-section, .alumni-spotlight {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s forwards ease-in-out;
}

/* Stagger the animation for different sections */
.events-section {
  animation-delay: 0.3s;
}

.trips-section {
  animation-delay: 0.6s;
}

.alumni-spotlight {
  animation-delay: 0.9s;
}

/* Animation keyframes */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Footer styles */
.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px;
  margin-top: 40px;
}

.footer-content p {
  margin: 10px 0;
}
