/* Trips section layout */
.trips-section {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  /* Trip card */
  .trip-card {
    position: relative;
    width: 100%;
    height: 500px; /* Adjust for desired card height */
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  /* Overlay div to position text in the center of the image */
  .trip-overlay {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .trip-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.5); /* Darken the image more for better text contrast */
  }
  
  /* Trip details positioned centrally */
.trip-details {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white; /* All text white */
}

/* Centered text, larger size */
.centered-text {
  font-size: 15rem; /* Increase text size */
  font-weight: bold;
  margin-bottom: 15px;
  color: white !important; /* Ensure the text is white */
}

/* Learn More button styling */
.learn-more-button {
  margin-top: 10px;
  background-color: #007bff;
  font-size: 1.2rem; /* Larger button text */
  color: white;
}
