/* General sidebar styling */
.sidebar {
    width: 250px;
    height: 100vh;
    background-color: #2c3e50;
    color: white;
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .sidebar h2 {
    font-size: 22px;
    margin-bottom: 20px;
    color: #ecf0f1;
    text-align: center;
    border-bottom: 2px solid #ecf0f1;
    padding-bottom: 10px;
  }
  
  /* Navigation list */
  .sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
  }
  
  .sidebar ul li {
    margin: 15px 0;
  }
  
  .sidebar ul li a {
    color: #ecf0f1;
    text-decoration: none;
    font-size: 18px;
    transition: color 0.3s ease;
  }
  
  .sidebar ul li a:hover {
    color: #3498db;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .sidebar {
      width: 200px;
    }
  
    .sidebar h2 {
      font-size: 20px;
    }
  
    .sidebar ul li a {
      font-size: 16px;
    }
  }
  